.cruise_container {
    width: 100%;
    height: auto;
    max-height: 800px;
    background: radial-gradient(circle, rgba(66, 216, 189, 0.6) 0%, rgba(0, 163, 234, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
    margin: auto; 
  }
  
  .cruise_image {
    width: 100%;
    height: auto;
    max-height: 830px;
    object-fit: contain;
  }

