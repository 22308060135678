.destinations_container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    box-sizing: border-box;
    background-color: #F0F0F0;
  }
  .destinarios_cont{
    max-width: 1500px;
    width: 100%;
  }
  
  .destinations_title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .destinations_grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .destination_item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .destination_image {
    width: 100%;
    max-width: 254px;
    height: auto;

  }
  
  .destination_name {
    font-family: 'Inter', sans-serif;
    font-weight: 700; 
    font-size: 32px;
    text-align: center;
    margin-top: 10px;
  }
  
  .destinations_footer {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    margin-top: 40px;
  }
  
  @media (max-width: 768px) {
    .destinations_title, .destinations_footer {
      font-size: 32px;
    }
  
    .destination_name {
      font-size: 24px;
    }
  }
  
  @media (max-width: 480px) {
    .destinations_grid {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  
    .destination_image {
      max-width: 150px;
    }
  
    .destinations_title, .destinations_footer {
      font-size: 24px;
    }
  
    .destination_name {
      font-size: 18px;
    }
  }
  