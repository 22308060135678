.footer_container {
    width: 100%;
    background-color: #333;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer_content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer_left {
    display: flex;
    flex-direction: column;
  }
  
  .footer_text {
    color: #9CA2B1;
    font-size: 16px;
    margin: 0;
  }
  
  .footer_link_blue {
    color: #1365C9;
    font-size: 16px;
    text-decoration: none;
    text-align: left;
  }
  
  .footer_link_blue:hover {
    text-decoration: underline;
  }
  
  .footer_right {
    display: flex;
    gap: 20px;
  }
  
  .footer_link {
    color: white;
    font-size: 16px;
    text-decoration: none;
  }
  
  .footer_link:hover {
    text-decoration: underline;
  }
  
  /* Media queries para pantallas más pequeñas */
  @media (max-width: 768px) {
    .footer_content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer_right {
      margin-top: 10px;
    }
  }
  