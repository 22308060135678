.benefits_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benefits_header {
  max-width: 1100px;
  text-align: center;
  font-size: 32px;
  font-weight: 600; 
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 40px;
}

.benefits_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(235px, 1fr));
  gap: 20px;
  max-width: 1400px;
  width: 100%;
}

@media (min-width: 768px) {
  .benefits_grid {
    grid-template-columns: repeat(3, 1fr); 
  }
}

.benefit_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.benefit_image {
  width: 235px;
  height: 212px;
  object-fit: cover;
}

.benefit_text {
  max-width: 380px;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  padding: 10px;
}

.benefit_text strong {
  font-weight: bold;
}

@media (max-width: 768px) {
  .benefits_header {
    font-size: 24px;
  }

  .benefit_image {
    width: 200px;
    height: 180px;
  }

  .benefit_text {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .benefits_header {
    font-size: 15px;
  }

  .benefit_image {
    width: 150px;
    height: 135px;
  }

  .benefit_text {
    font-size: 16px;
  }
}
