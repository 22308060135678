.reservation_container {
    width: 100%;
    height: auto;
    background: url('../../public/background_2.png') center/cover no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    box-sizing: border-box;
  }
  
  .reservation_header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800; /* Extra Bold */
    font-size: 48px;
    text-align: center;
    color: white;
    margin-bottom: 40px;
  }
  
  .reservation_content {
    width: 100%;
    max-width: 1470px;
    height: 570px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .reservation_image {
    width: 50%;
    height: auto;
    border-radius: 20px;
    margin-top: -5%;
  }
  
  .reservation_text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: black;
    width: 50%;
    padding-left: 20px;
    text-align: left;
  }
  
  @media (max-width: 768px) {
    .reservation_header {
      font-size: 32px;
    }
  
    .reservation_content {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  
    .reservation_image {
      width: 100%;
      margin-top: -50px;
    }
  
    .reservation_text {
      width: 100%;
      font-size: 18px;
      padding-left: 0;
      text-align: center;
    }
  }
  
  @media (max-width: 480px) {
    .reservation_header {
      font-size: 28px;
    }
  
    .reservation_text {
      font-size: 16px;
    }
  }
  