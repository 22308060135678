.why-choose-us {
  max-width: 1900px;
  margin: 0 auto;
  padding: 10px 10px;
  text-align: center;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.left-container {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 10vw;
}

.logo {
  max-width: 362px;
  margin-bottom: 20px;
}

h1 {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 40px;
}

.benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  width: 100%;
}

.benefit-card {
  display: flex;
  align-items: flex-start;
  text-align: left;
  padding: 20px;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.text h2 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.text p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  margin: 0;
  max-width: 300px;
}

.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.highlight-image {
  max-width: 638px;
  height: auto;
  margin-bottom: 20px;
}

.discover-button {
  padding: 10px 20px;
  background-color: #024cae;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  max-width: 0 auto;
  border: none;
}

@media (max-width: 1200px) {
  .benefits {
    grid-template-columns: repeat(2, 1fr);
  }

  .highlight-image {
    max-width: 500px;
  }
}

@media (max-width: 800px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .benefits {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 20px;
  }

  .highlight-image {
    max-width: 100%;
  }

  .right-container {
    width: 100%;
  }

  .left-container,
  .right-container {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .highlight-image {
    max-width: 80%;
  }
}
