.navbar_container {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar_inner {
    width: 100%;
    max-width: 1980px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  
  .navbar_logo img {
    margin-top: 10px;
    max-width: 250px;
    max-height: 60px;
  }
  
  .navbar_menu {
    display: flex;
    align-items: center;
    margin-left: 20px;
    flex-grow: 1;
  }
  
  .navbar_item {
    color: black;
    font-size: 14.5px;
    margin: 0 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .navbar_item_dot {
    font-weight: bold;
    color: #024CAE;
    margin-left: 5px;
    margin-top: 5px;
  }
  
  .navbar_item_selected {
    color: #024CAE;;
  }
  
  .navbar_button {
    width: 180px;
    height: 52px;
    background-color: #024CAE;;
    color: white;
    font-size: 13px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .navbar_hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .navbar_hamburger div {
    width: 24px;
    height: 2px;
    background-color: black;
  }
  
  @media (max-width: 800px) {
    .navbar_menu {
      display: none;
      flex-direction: column;
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 80px;
      right: 0;
      padding: 20px 0;
      box-shadow: 0px 8px 16px rgba(0,0,0,0.1);
    }
  
    .navbar_menu.open {
      display: flex;
      z-index: 1;
    }
    .navbar_inner {
      justify-content: space-between;
    }
  
    .navbar_item {
      margin: 10px 0;
      font-size: 18px;
    }
  
    .navbar_button {
      display: none;
    }
  
    .navbar_hamburger {
      display: flex;
    }
  }
  