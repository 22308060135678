.travel_container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 20px 0;
  }
  
  .travel_inner_container {
    position: relative;
    width: 100%;
    max-width: 1900px;
    text-align: center;
  }
  
  .center_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .travel_circles {
    width: 100%;
    max-width: 1300px;
    height: auto;
  }
  
  .travel_text_image {
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin-top: -8%;
  }
  
  .travel_leaf {
    position: absolute;
    width: 100%;
    max-width: 552px;
    height: auto;
    z-index: -1;
  }
  
  .travel_leaf_left {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  
  .travel_leaf_right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  
  /* Media queries para pantallas más pequeñas */
  @media (max-width: 1200px) {
    .travel_circles {
      max-width: 1100px;
    }
  
    .travel_text_image {
      max-width: 1000px;
    }
  
    .travel_leaf {
      max-width: 400px;
    }
  }
  
  @media (max-width: 768px) {
    .travel_circles {
      max-width: 700px;
    }
  
    .travel_text_image {
      max-width: 900px;
    }
  
    .travel_leaf {
      max-width: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .travel_circles {
      max-width: 700px;
    }
  
    .travel_text_image {
      max-width: 500px;
    }
  
    .travel_leaf {
      max-width: 200px;
    }
  }
  