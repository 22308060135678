.main_container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main_content {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_container {
  text-align: center;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.main_title {
  font-size: 10rem; 
  color: white;
  margin: 0;
}

.main_subtitle {
  font-size: 4rem;
  color: #EB3030;
  margin: 0;
}

/* Media queries for smaller screens */
@media (max-width: 1200px) {
  .main_title {
    font-size: 8rem;
  }

  .main_subtitle {
    font-size: 3.5rem;
  }
}

@media (max-width: 992px) {
  .main_title {
    font-size: 6rem;
  }

  .main_subtitle {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .main_title {
    font-size: 4.5rem;
  }

  .main_subtitle {
    font-size: 2.5rem;
  }
}

@media (max-width: 576px) {
  .main_title {
    font-size: 3.5rem;
  }

  .main_subtitle {
    font-size: 2rem;
  }
}

