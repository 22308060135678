.video_container {
    width: 100%;
    max-width: 1012px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    box-sizing: border-box;
  }
  
  .testimonial-video {
    width: 100%;
    height: auto;
    border-radius: 20px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 40px;
  }
  
  .reserve_button {
    margin-top: 20px;
    width: 180px;
    height: 52px;
    background-color: #024CAE;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .reserve_button:hover {
    background-color: darkblue;
  }
  