.testimonial-container {
    width: 100%;
    background: radial-gradient(circle at 55% 50%, rgba(66, 216, 189, 1) 0%, rgba(0, 163, 234, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0px;
    position: relative;
    overflow: visible;
  }
  
  .testimonial-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1900px;
    z-index: 1;
  }
  
  .testimonial-left {
    flex: 1;
  }
  .fot_foto{
    position: relative;

  }
  .author-image2{
    position: absolute;
    left: -50%;
  }
  
  .testimonial-main-image {
    width: 100%;
    max-width: 650px;
    height: auto;
  }
  
  .testimonial-right {
    flex: 1;
    color: white;
    text-align: center;
    align-content: start;
    margin-left: -5%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
  .testimonial-right h2 {
    font-family: 'Inter', sans-serif;
    font-size: 34px;
    margin: 10px;
  }
  
  .testimonial-right h1 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    color: #FF422C;
    margin: 10px;
  }
  
  .testimonial-quote {
    max-width: 840px;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    margin: 20px 0;
    padding: 0 20px; 
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .author-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .author-info {
    color: white;
    margin: 0;
  }
  
  .author-name {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    margin: 10px;
    font-weight: bold;
  }
  
  .author-country {
    font-family: 'Inter', sans-serif;
    margin: 0px;
    font-size: 16px;
  }
  
  .author-rating .star {
    color: #FFD700;
    font-size: 20px;
  }
  
  .testimonial-plant-left, .testimonial-plant-right {
    position: absolute;
    width: 236px;
    height: auto;
    z-index: 0;
    pointer-events: none;
  }
  
  .testimonial-plant-left {
    bottom: -20%;
    left: 0;
    z-index: 1;
  }
  
  .testimonial-plant-right {
    top: -20%;
    right: 0px;
    z-index: 1;
  }
  
  .testimonial-diagonal {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 1200px) {
    .testimonial-right {
      text-align: center;
      order: 1;
    }
  
    .testimonial-left {
      order: 2;
    }
  
    .testimonial-content {
      flex-direction: column;
    }
  
    .testimonial-main-image {
      max-width: 80%;
    }
  
    .testimonial-quote, .author-info {
      text-align: center;
    }
  
    .testimonial-plant-left, .testimonial-plant-right {
      width: 120px; 
    }
  
    .testimonial-plant-right {
      top: -10%;
    }
  
    .testimonial-right h2 {
      font-size: 24px;
    }
  
    .testimonial-right h1 {
      font-size: 36px;
    }
  
    .testimonial-quote {
      font-size: 18px;
      padding: 0 10px; 
      margin-left: 15px;
    }
  
    .author-name {
      font-size: 20px;
    }
  
    .author-country {
      font-size: 14px;
    }
  }
  
  @media (max-width: 768px) {
    .testimonial-plant-left, .testimonial-plant-right {
      width: 100px; 
    }
  
    .testimonial-right h2 {
      font-size: 20px;
    }
  
    .testimonial-right h1 {
      font-size: 30px;
    }
  
    .testimonial-quote {
      font-size: 16px;
      padding: 0 5px; 
      margin-left: 15px;
    }
  
    .author-name {
      font-size: 18px;
    }
  
    .author-country {
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .testimonial-plant-left, .testimonial-plant-right {
      width: 80px; 
    }
  
    .testimonial-right h2 {
      font-size: 18px;
    }
  
    .testimonial-right h1 {
      font-size: 24px;
    }
  
    .testimonial-quote {
      font-size: 14px;
      padding: 3%;
      max-width: 90%;
      margin-left: 15px;
    }
  
    .author-name {
      font-size: 16px;
    }
  
    .author-country {
      font-size: 10px;
    }

    .testimonial-plant-left {
        bottom: 0%;
      }
      
      .testimonial-plant-right {
        top: -0%;
      }
      .fot_foto{
        margin-left: 20%;
    
      }
  }
  