.dreams_container {
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    box-sizing: border-box;
    position: relative;
  }
  
  .dreams_header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 48px;
    text-align: center;
    color: #1F7F95;
    max-width: 800px;
    margin-bottom: 40px;
  }
  
  .dreams_content {
    width: 100%;
    max-width: 1470px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .dreams_left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dreams_left_image {
    width: 100%;
    max-width: 800px;
    height: auto;
    object-fit: contain;
  }
  
  .dreams_right {
    flex: 1;
    padding-left: 20px;
  }
  
  .dreams_text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: black;
    text-align: left;
  }
  
  .dreams_text strong {
    font-weight: 800;
  }
  
  .dreams_plane {
    position: absolute;
    top: 0;

    width: 20vw;
    height: auto;
  }
  @media (max-width: 768px) {
    .dreams_header {
      font-size: 32px;
    }
  
    .dreams_content {
      flex-direction: column;
      align-items: center;
    }
  
    .dreams_left_image {
      max-width: 100%;
      margin-bottom: 20px;
      margin-top: -70px;
    }
  
    .dreams_text {
      font-size: 24px;
      text-align: center;
      padding-left: 0;
    }
  
    .dreams_plane {
      position: static;
      width: 80px;
      margin-top: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .dreams_header {
      font-size: 28px;
    }
  
    .dreams_text {
      font-size: 20px;
    }
  
    .dreams_plane {
      width: 60px;
    }
  }
  